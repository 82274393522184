a,
a:hover,
a:focus,
a:active {
  text-decoration: underline;
  color: inherit;
}

.Absolute-Center {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 50%;
  right: 0;
}

.vimeo {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0;
  /* height: 0; */
  overflow: hidden;
  margin: 0 auto;
}

.vimeo2 {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0;
  height: 0;
  overflow: hidden;
}

.vimeo iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 90vh;
}

.hover:hover {
  cursor: pointer;
}

.routerRoot {
  background-color: #eeeeee;
  min-height: 100vh;
  width: 100%;
}

@media screen and (min-width: 960px) {
  .routerRoot {
    width: calc(100% - 240px);
    margin-left: 240px;
  }
}

.react-datepicker-wrapper {
  width: 100%;
}

#ace-editor {
  min-width: 98%;
  min-height: 500px;
}

#user-progress-tbcell {
  padding: 1rem !important;
}

.ant-picker-dropdown {
  z-index: 999999;
}
